<template>
  <a @click="modalShow = true" class="custom-btn delete-btn">
    <bootstrap-icon :icon="icon" size="1x" />
    <b-modal
      v-model="modalShow"
      modal-class="delete-dialog"
      cancel-title="Cancel"
      ok-title="Submit"
      @ok="confirmAction"
    >
      <h3>{{ $t("messages.confirmAction") }}</h3>
    </b-modal>
  </a>
</template>

<script>
export default {
  name: "ResendBtn",
  props: {
    icon: { type: String, default: 'envelope' }
  },
  components: {},
  data() {
    return {
      modalShow: false,
    };
  },
  methods: {
    confirmAction() {
      this.$emit("pressAction");
    },
  },
};
</script>
