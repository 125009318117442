<template>
  <router-link :to="pathUrl" class="custom-btn view-btn">
    <bootstrap-icon
            icon="eye"
            size="1x"
          />
  </router-link>
</template>

<script>
export default {
  name: 'ViewBtn',
  props: {
    pathUrl: { type: String, default: '' },
  },
}
</script>
